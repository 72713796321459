<template>
  <div class="card">
    <div class="card-title">
      <h5 class="card-title">{{ cardTitle }}</h5>
      <b-form-select id="sort-select" name="sort-select" title="Sort" v-model="selectedSort" :options="sortOptions"
        @change="sortChange()"></b-form-select>
    </div>
    <div class="card-body">
      <b-table class="mrpp-table" :fields="fields" :items="items" small v-if="items.length > 0">
        <template v-slot:cell(icon)="data">
          <img :src="getImageLink(data.value)" v-if="data.item.icon_alt_text !== 'unk'" class="thumbIcon" fluid
            :alt="data.item.icon_alt_text" />
        </template>
        <template v-slot:cell(nameLink)="data">
          <a @click.prevent="nameLinkClick(data.item)" href="#">{{ data.item.nameLink }}</a>
        </template>
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(download_link)="data">
          <a :href="data.value" v-if="data.item.icon_alt_text !== 'unk' && data.item.download_link" target="_blank">
            <img :src="getImageLink(data.item.downloadIcon)" class="downloadIcon" fluid alt="download" />
          </a>
        </template>
      </b-table>
      <div v-if="items.length === 0" class="noItemsMsg">{{ translations.tcNoItemsToDisplay }}</div>
      <b-button @click="btnClickRedirect" v-if="showLinkButton" class="btn-outline">{{ btnText }}</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'card-mrp-lp',
  props: {
    category: {
      type: String,
      default: '',
    },
    cardTitle: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: [],
    },
    fields: {
      type: Array,
      default: [],
    },
    btnUrl: {
      type: String,
      default: '/',
    },
    btnText: {
      type: String,
      default: 'View More',
    },
    showLinkButton: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: 'name',
    },
    sortOptions: {
      type: Array,
      default: [
        {
          value: 'name',
          text: 'Name',
        },
        {
          value: 'mostRecent',
          text: 'Most Recent',
        },
      ],
    },
    translations: {
      type: Object,
      default: () => {
        return {
          'tcNoItemsToDisplay': 'No items to display',
        }
      },
    },
  },
  data() {
    return {
      selectedSort: 'mostRecent',
      sortDesc: false,
    }
  },
  methods: {
    ...mapActions({}),
    getImageLink(mediaid) {
      return this.mrpSiteCoreBaseURL + '/getimage/' + mediaid
    },
    btnClickRedirect() {
      this.$router.push(this.btnUrl)
    },
    nameLinkClick(data) {
      this.$emit('name_link_click', data)
    },
    showVideo(fileId, fileDownloadLink, filename) {
      this.videoID = fileId.replace('https://player.vimeo.com/video/', '')
      this.videoFileDownloadLink = fileDownloadLink
      this.videoName = filename
      this.$refs.player.pause()
      this.displayVideo = 'block'
      window.scrollTo(0, 400)
    },
    sortChange() {
      setTimeout(() => {
        // this is to give the change event a tick to update the value
        const sortInfo = {
          sortBy: this.selectedSort,
          programCategory: this.category,
        }
        this.$emit('sort_changed', sortInfo)
      }, 0)
    },
  },
  computed: {
    ...mapGetters({
      mrpSiteCoreBaseURL: 'mrp/mrpSiteCoreBaseURL',
    }),
  },
  async created() {
    this.selectedSort = this.sortBy
  },
}
</script>

<style>
.thumbIcon {
  max-width: 30px;
  height: 30px;
}
</style>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.hidden_header {
  display: none;
}

.downloadIcon {
  max-width: 30px;
}

.noItemsMsg {
  padding: 12px 20px;
  font-style: italic;
}

.btn-outline {
  padding: 12px 20px !important;
  color: #007bff !important;

  &:hover {
    color: $teal-600  !important;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 !important;

  .card-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $primary-color;
    color: #ffffff;
    padding: 5px 10px;
    margin: 0;
    font-family: $DIN;
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 44px;
    text-transform: uppercase;

    select {
      max-width: 50%;
      padding-top: 5px;
    }
  }

  .card-body {
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .icon {
      margin: 0 auto;
    }

    .btn-outline {
      background-color: #ffffff;
      border: 0;
      padding: 12px;
      margin-top: 0;
    }
  }

  .border-bot {
    border-bottom: 8px solid $primary-color;
    border-left: none !important;
    border-right: none !important;
    margin-right: none !important;
  }
}
</style>
<style lang="scss">
.mrpp-table {
  td {
    padding: 20px 20px 20px 20px;
  }

  td.mrpIconClass {
    max-width: 125px;
    box-sizing: border-box;
  }

  td.mrpDownloadClass {
    text-align: right;
    box-sizing: border-box;
  }
}
</style>
